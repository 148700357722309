const containsOperator = {
    key: 'Contains',
    text: 'Contains'
};

const equalOperator = {
    key: '==',
    text: 'Equals'
};

const lessThanOperator = {
    key: '<',
    text: 'Less Than'
};

const lessThanEqualOperator = {
    key: '<=',
    text: 'Less Than Equal'
};

const greaterThanOperator = {
    key: '>',
    text: 'Greater Than'
};

const greaterThanEqualOperator = {
    key: '>=',
    text: 'Greater Than Equal'
};

const betweenOperator = {
    key: 'Between',
    text: 'Between'
};

const stringDataType = {
    type: 'Text',
    operators: [containsOperator, equalOperator]
};

const intDataType = {
    type: 'Number',
    operators: [
        equalOperator,
        lessThanOperator,
        lessThanEqualOperator,
        greaterThanOperator,
        greaterThanEqualOperator,
        betweenOperator
    ]
};

const dateDataType = {
    type: 'Date',
    operators: [
        equalOperator,
        lessThanOperator,
        lessThanEqualOperator,
        greaterThanOperator,
        greaterThanEqualOperator,
        betweenOperator
    ]
};

const booleanDataType = {
    type: 'CheckBox',
    operators: [equalOperator]
};

export {
    containsOperator,
    equalOperator,
    lessThanOperator,
    lessThanEqualOperator,
    greaterThanOperator,
    greaterThanEqualOperator,
    betweenOperator,
    stringDataType,
    intDataType,
    dateDataType,
    booleanDataType
};
