import { timeSpanToTimeParts } from '@/helpers';
import { helpers } from 'vuelidate/lib/validators';

/**
 * Check if value is above or equal a threshold.
 * @param {String} timeSpan - Time Span value in 'd.hh:mm:ss' or 'hh:mm:ss' format
 * @returns {Boolean} True, if value under test is greater than or equal the threshold, otherwise false.
 */
export const timeSpanMinValue = (timeSpan) =>
    helpers.withParams({ type: 'timeSpanMinValue', min: timeSpan }, (value) => {
        if (!helpers.req(value)) 
            return true;
        const minSeconds = timeSpanToTimeParts(timeSpan).totalSeconds;
        const given = timeSpanToTimeParts(value).totalSeconds;
        return minSeconds <= given;
    });

/**
 * Check if value is below or equal a threshold.
 * @param {String} timeSpan - Time Span value in 'd.hh:mm:ss' or 'hh:mm:ss' format
 * @returns {Boolean} True, if value under test is less than or equal the threshold, otherwise false.
 */
export const timeSpanMaxValue = (timeSpan) =>
    helpers.withParams({ type: 'timeSpanMaxValue', max: timeSpan }, (value) => {
        if (!helpers.req(value)) 
            return true;
        const maxSeconds = timeSpanToTimeParts(timeSpan).totalSeconds;
        const given = timeSpanToTimeParts(value).totalSeconds;
        return maxSeconds >= given;
    });
