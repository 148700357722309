<template>
    <div>
        <div v-if="order > 1" class="operator-wrapper">
            <form-group name="mainOperator" label="And/Or" attribute="And/Or">
                <md-select v-model="subCondition.mainOperator">
                    <md-option v-for="o in mainOperatorOptions" :value="o.key" :key="`${order}-main-op-${o.text}`">
                        {{ o.text }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <span v-else class="operator-wrapper">&nbsp;</span>

        <!-- shipment, stop addresses, load, custom fields -->
        <div class="lg-condition-wrapper">
            <form-group
                name="variable"
                label="Variable"
                attribute="Variable"
                :class="{
                    error: validator.variable.$error
                }"
            >
                <md-select v-model="selectedVariable" @md-selected="selectedVariableChanged">
                    <md-optgroup v-for="g in conditionVariables" :label="g.group" :key="`${order}-var-grp-${g.group}`">
                        <md-option
                            v-for="o in g.options"
                            :value="o.dropdownValue"
                            :key="`${order}-var-${g.group}-${o.parentObject}-${o.text}`"
                        >
                            {{ o.text }}
                        </md-option>
                    </md-optgroup>
                </md-select>
            </form-group>
        </div>
        <div class="lg-condition-wrapper">
            <form-group
                name="operator"
                label="Operator"
                attribute="Operator"
                :class="{
                    error: validator.operator.$error
                }"
            >
                <md-select v-model="subCondition.operator" :disabled="!subCondition.variable">
                    <md-option v-for="o in variableOperatorOptions" :value="o.key" :key="`${order}-operator-${o.text}`">
                        {{ o.text }}
                    </md-option>
                </md-select>
            </form-group>
        </div>
        <div
            class="lg-condition-wrapper"
            name="name"
            :class="{
                error: validator.value.$error,
                'field-text': subCondition.dataType == 'Text',
                'checkbox-field': subCondition.dataType == 'CheckBox',
                'dropdown-field': subCondition.dataType == 'Dropdown',
                'textarea-custom-field': subCondition.dataType == 'MultiLineText'
            }"
        >
            <CustomFieldInputs
                v-if="subConditionFieldInput"
                :custom-field-definition="subConditionFieldInput"
                :initial-value="subConditionFieldInputValue"
                @changed="handleValueChanged"
            />
        </div>
        <div
            v-if="showValue2"
            class="lg-condition-wrapper"
            name="name"
            :class="{
                error: validator.value2.$error,
                'field-text': subCondition.dataType == 'Text',
                'checkbox-field': subCondition.dataType == 'CheckBox',
                'dropdown-field': subCondition.dataType == 'Dropdown',
                'textarea-custom-field': subCondition.dataType == 'MultiLineText'
            }"
        >
            <CustomFieldInputs
                :custom-field-definition="subConditionFieldInput2"
                :initial-value="subConditionFieldInputValue2"
                @changed="handleValue2Changed"
            />
        </div>
        <span v-else class="lg-condition-wrapper">&nbsp;</span>

        <md-button
            title="Remove sub-condition"
            class="md-danger md-just-icon md-round remove-button"
            @click.prevent="removeSubCondition"
        >
            <md-icon>close</md-icon>
        </md-button>
    </div>
</template>

<script>
import { stringDataType, intDataType, dateDataType, booleanDataType } from '@/utils/CustomActionConstants';
import {
    mainOperatorOptions,
    booleanOperatorOptions,
    shipmentOptions,
    shipmentLoadOptions,
    shipmentContentsOptions,
    stopOptions
} from '@/utils/PlaceBookingConstants';
import { mapGetters } from 'vuex';
import { CustomFieldInputs } from '@/components';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import moment from 'moment';

export default {
    name: 'ConditionBuilderItem',
    mixins: [GeneralMixin],
    components: { CustomFieldInputs },
    props: {
        order: { type: Number, required: true },
        subCondition: { type: Object, default: () => {} },
        validator: { type: Object, required: true }
    },
    data() {
        return {
            subConditionFieldInput: { type: 'Text' },
            subConditionFieldInput2: { type: 'Text' },
            subConditionFieldInputValue: null,
            subConditionFieldInputValue2: null,
            mainOperatorOptions,
            booleanOperatorOptions,
            conditionVariables: [],
            shipmentOptions, // fields to choose from
            shipmentLoadOptions, // fields to choose from
            shipmentContentsOptions, // fields to choose from
            stopOptions, // fields to choose from
            variableOperatorOptions: [],
            selectedVariable: null,
            latestTempKey: null, // to keep track of variable change
            prevTempKey: null // to keep track of variable change
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        showValue2() {
            return this.subCondition?.operator === 'Between';
        }
    },
    watch: {
        subCondition(newVal, oldVal) {
            this.latestTempKey = newVal?.tempKey;
            this.prevTempKey = oldVal?.tempKey;
            // when sub-condition is removed
            this.updateControls();
            this.updateCustomFieldInputDefinition({ ...this.subCondition });
        },

        'subCondition.variable': {
            immediate: true,
            handler(newVal, oldVal) {
                // reset the Operator and Value in order to display correct options and input control
                if (this.subCondition.tempKey !== this.prevTempKey) {
                    this.updateCustomFieldInputDefinition({ ...this.subCondition });
                } else {
                    this.updateCustomFieldInputDefinition({
                        ...this.subCondition,
                        operator: null,
                        value: null,
                        value2: null
                    });
                    this.$emit('subConditionChanged', {
                        ...this.subCondition,
                        operator: null,
                        value: null,
                        value2: null
                    });
                }
            }
        }
    },
    methods: {
        removeSubCondition() {
            this.$emit('subConditionRemoved', this.order);
        },
        selectedVariableChanged(dropdownValue) {
            this.populateCondition(dropdownValue);
        },
        populateCondition(dropdownValue) {
            if (dropdownValue) {
                const allVariables = this.conditionVariables.reduce((result, x) => result.concat(x.options), []);
                const variable = allVariables.find((x) => x.dropdownValue === dropdownValue);
                this.variableOperatorOptions = [...variable.dataType.operators];
                this.subCondition.dataType = variable.dataType.type;
                this.subCondition.variable = variable.key;
                this.subCondition.parentObject = variable.parentObject;
            }
        },
        updateControls() {
            if (this.subCondition) {
                const dataType = this.mapCustomFieldDataType(this.subCondition.dataType);
                this.variableOperatorOptions = [...dataType.operators];

                this.selectedVariable = this.subCondition.parentObject
                    ? `${this.subCondition.parentObject}-${this.subCondition.variable}`
                    : this.subCondition.variable;
            }
        },
        updateCustomFieldInputDefinition(subCon) {
            if (subCon) {
                this.subConditionFieldInput = this.getSubConditionFieldInput('Value', subCon);
                this.subConditionFieldInput2 = this.getSubConditionFieldInput('2nd Value', subCon);
                this.subConditionFieldInputValue = subCon.value;
                this.subConditionFieldInputValue2 = subCon.value2;
            }
        },
        mapCustomFieldDataType(customFieldType) {
            switch (customFieldType) {
                case 'Date':
                    return dateDataType;
                case 'CheckBox':
                    return booleanDataType;
                case 'Number':
                    return intDataType;
                case 'MultiLineText':
                case 'Text':
                default:
                    return stringDataType;
            }
        },
        addShipmentVariables() {
            const options = this.shipmentOptions.map((x) => {
                const dropdownValue = x.parentObject ? `${x.parentObject}-${x.key}` : x.key;
                return {
                    parentObject: x.parentObject,
                    key: x.key,
                    text: x.text,
                    dataType: x.dataType,
                    dropdownValue
                };
            });

            this.conditionVariables.push({
                group: 'Shipment',
                options
            });
        },
        addCustomFieldsVariables() {
            this.shipmentCustomFieldList = this.user.shipmentCustomFieldDefinitions;

            if (this.shipmentCustomFieldList && Array.isArray(this.shipmentCustomFieldList)) {
                const options = this.shipmentCustomFieldList.map((x) => {
                    return {
                        parentObject: 'CustomFields',
                        key: x.name,
                        text: x.label,
                        dataType: this.mapCustomFieldDataType(x.type),
                        dropdownValue: `CustomFields-${x.name}`
                    };
                });

                this.conditionVariables.push({
                    group: 'Custom Fields',
                    options
                });
            }
        },
        addShipmentContentsVariables() {
            const options = this.shipmentContentsOptions.map((x) => {
                return {
                    parentObject: 'Contents',
                    key: x.key,
                    text: x.text,
                    dataType: booleanDataType,
                    dropdownValue: `Contents-${x.key}`
                };
            });

            this.conditionVariables.push({
                group: 'Contents',
                options
            });
        },
        addShipmentLoadVariables() {
            const options = this.shipmentLoadOptions.map((x) => {
                return {
                    parentObject: 'Load',
                    key: x.key,
                    text: x.text,
                    dataType: intDataType,
                    dropdownValue: `Load-${x.key}`
                };
            });

            this.conditionVariables.push({
                group: 'Load',
                options
            });
        },
        addStopVariables(stopObjectName) {
            const group = stopObjectName === 'PickupStop' ? 'Pickup Stop' : 'Drop Stop';
            const options = this.stopOptions.map((x) => {
                const parentObject = !x.parentObject ? stopObjectName : `${stopObjectName}.${x.parentObject || ''}`;
                return {
                    parentObject,
                    key: x.key,
                    text: x.text,
                    dataType: x.dataType,
                    dropdownValue: `${parentObject}-${x.key}`
                };
            });

            this.conditionVariables.push({
                group,
                options
            });
        },
        getSubConditionFieldInput(label, subCon) {
            const fieldInput = {
                ...subCon,
                type: subCon.dataType ?? 'Text',
                name: subCon.variable,
                label,
                options: []
            };

            if (subCon.dataType === 'CheckBox') {
                fieldInput.type = 'Dropdown'; // to make it dropdown
                fieldInput.options = this.booleanOperatorOptions.map((x) => {
                    return {
                        value: x.key,
                        label: x.text
                    };
                });
            } else if (subCon.dataType === 'Number') {
                fieldInput.type = 'Text'; // the component does not support Number
            }
            return { ...fieldInput };
        },
        handleValueChanged({ name, value, type }) {
            let parsedValue = value;
            if (type === 'Date') {
                parsedValue = !moment(parsedValue).isValid() ? null : moment(parsedValue).format('YYYY-MM-DD');
            }

            this.$emit('subConditionChanged', { ...this.subCondition, value: parsedValue });
        },
        handleValue2Changed({ name, value, type }) {
            let parsedValue = value;
            if (type === 'Date') {
                parsedValue = !moment(parsedValue).isValid() ? null : moment(parsedValue).format('YYYY-MM-DD');
            }

            this.$emit('subConditionChanged', { ...this.subCondition, value2: parsedValue });
        }
    },
    mounted() {
        this.addShipmentVariables();
        this.addShipmentLoadVariables();
        this.addShipmentContentsVariables();
        this.addCustomFieldsVariables();
        this.addStopVariables('PickupStop');
        this.addStopVariables('DropStop');
        this.latestTempKey = this.subCondition?.tempKey;
        this.prevTempKey = this.subCondition?.tempKey;
        this.updateControls();
        this.updateCustomFieldInputDefinition(this.subCondition);
    }
};
</script>

<style lang="scss" scoped>
::v-deep .form-wrapper {
    width: 100%;
}
.operator-wrapper {
    margin: 5px;
    width: 90px;
    padding: 2px;
    display: inline-block;
}

.lg-condition-wrapper {
    margin: 5px;
    width: 170px;
    padding: 2px;
    display: inline-block;
}

.error {
    border: solid 1px red;
}

.md-button.remove-button {
    min-width: 24px;
    width: 24px;
    height: 24px;
    font-size: 12px;

    .md-icon.md-icon-font.md-theme-default {
        color: red !important;
    }
}
</style>
