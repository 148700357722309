<template>
    <div>
        <form-group name="settings.preferredQuote" label="Preferred Quote">
            <md-select v-model="customActionModel.settings.preferredQuote">
                <md-option v-for="item in preferredQuoteOptions" :key="item" :value="item">
                    {{ item }}
                </md-option>
            </md-select>
        </form-group>
        <div class="md-layout-item" v-if="customActionModel.settings.preferredQuote === 'Custom'">
            <ConditionBuilder
                v-for="con in validator.settings.conditions.$each.$iter"
                :key="con.$model.tempKey"
                :condition="con.$model"
                :validator="con"
                @conditionRemoved="conditionRemovedHandler"
            />

            <div class="condition-button-wrapper">
                <md-button @click.prevent="addEmptyCondition">Add Condition Block</md-button>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import { CARRIER_CUSTOM_CONDITION_DEFAULTS } from '@/utils/defaults';
import ConditionBuilder from './ConditionBuilder';

export default {
    name: 'PlaceCarrierBooking',
    props: {
        customActionModel: { type: Object, default: () => null },
        validator: { type: Object, required: true },
        isUpdate: { type: Boolean, default: false }
    },
    components: { ConditionBuilder },
    data() {
        return {
            preferredQuoteOptions: ['Cheapest', 'Fastest', 'Custom'],
            conditions: []
        };
    },
    computed: {
        ...mapGetters({
            allTeamCarriers: 'team/teamCarriers'
        }),
        activeCarriers() {
            if (this.allTeamCarriers && this.allTeamCarriers.length)
                return this.allTeamCarriers.filter((x) => x.status === 'Active');

            return [];
        }
    },
    watch: {
        'customActionModel.settings.preferredQuote': {
            immediate: true,
            handler(newVal, oldVal) {
                if (!this.isUpdate) {
                    this.customActionModel.settings.conditions = [];
                    if (this.customActionModel.settings.preferredQuote === 'Custom') {
                        this.addEmptyCondition();
                    }
                } else if (this.customActionModel.settings.preferredQuote === 'Custom') {
                    this.addFallbackCondition();
                }
            }
        }
    },
    methods: {
        addFallbackCondition() {
            if (!this.customActionModel.settings.conditions) 
                this.customActionModel.settings.conditions = [];

            const currentConditionCount = this.customActionModel.settings.conditions.length;

            if (currentConditionCount === 0) {
                // fallback condition
                this.customActionModel.settings.conditions.push({
                    tempKey: _.random(-1, -10000),
                    order: this.customActionModel.settings.conditions.length + 1,
                    subConditions: [],
                    assignTo: 'Any',
                    assignToOption: null,
                    isFallbackCondition: true,
                    name: 'Fallback Condition' // consider making it a constant
                });
            }
        },
        addEmptyCondition() {
            if (!this.customActionModel.settings.conditions) 
                this.customActionModel.settings.conditions = [];

            const currentConditionCount = this.customActionModel.settings.conditions.length;

            if (currentConditionCount === 0) {
                this.addFallbackCondition();
            }

            this.customActionModel.settings.conditions.splice(
                this.customActionModel.settings.conditions.length - 1,
                0,
                {
                    tempKey: _.random(-1, -10000),
                    order: this.customActionModel.settings.conditions.length + 1,
                    subConditions: [
                        {
                            ...CARRIER_CUSTOM_CONDITION_DEFAULTS(),
                            order: 1,
                            tempKey: _.random(-1, -10000)
                        }
                    ],
                    assignTo: 'Any',
                    assignToOption: null,
                    name: null
                }
            );

            this.updateConditionOrder();
        },
        updateConditionOrder() {
            const current = [...this.customActionModel.settings.conditions];

            for (let i = 0; i < current.length; i++) {
                current[i].order = i + 1;
            }

            this.$set(this.customActionModel.settings, 'conditions', [...current]);
        },
        conditionRemovedHandler(order) {
            const current = cloneDeep(this.customActionModel.settings.conditions);
            const orderIndex = current.findIndex((x) => x.order === order);
            current.splice(orderIndex, 1);
            if (current.length) {
                this.$set(this.customActionModel.settings, 'conditions', [...current]);
            } else {
                this.$set(this.customActionModel.settings, 'conditions', []);
            }

            this.updateConditionOrder();
        }
    },
    mounted() {
        if (
            !this.isUpdate &&
            this.customActionModel.settings.preferredQuote === 'Custom' &&
            (!this.customActionModel?.settings?.conditions || !this.customActionModel?.settings?.conditions.length)
        ) {
            this.customActionModel.settings.conditions = [];
            this.addEmptyCondition();
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .md-checkbox-container {
    top: 10px;
}

::v-deep .md-checkbox-label {
    opacity: 1 !important;
    color: black !important;
}

::v-deep div.md-field.no-underline:after {
    height: 0px;
}

::v-deep .md-checkbox {
    margin-top: 0px;
}
</style>
