import { stringDataType, dateDataType } from './CustomActionConstants';

const mainOperatorOptions = [
    {
        key: '&&',
        text: 'And'
    },
    {
        key: '||',
        text: 'Or'
    }
];

const booleanOperatorOptions = [
    {
        key: 'true',
        text: 'True'
    },
    {
        key: 'false',
        text: 'False'
    }
];

const shipmentOptions = [
    {
        key: 'Notes',
        text: 'Notes',
        dataType: stringDataType
    },
    {
        key: 'Source',
        text: 'Source',
        dataType: stringDataType
    },
    {
        key: 'SourceReference',
        text: 'Source Reference',
        dataType: stringDataType
    },
    {
        key: 'TripDate',
        text: 'Trip Date',
        dataType: dateDataType
    },
    {
        parentObject: 'Brand',
        key: 'Name',
        text: 'Brand Name',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Name',
        text: 'Contact Name',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Phone',
        text: 'Contact Phone',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Email',
        text: 'Contact Email',
        dataType: stringDataType
    },
    {
        parentObject: 'Customer',
        key: 'Name',
        text: 'Customer Name',
        dataType: stringDataType
    },
    {
        parentObject: 'TeamRegion',
        key: 'Name',
        text: 'Team Region Name',
        dataType: stringDataType
    }
    // Skills
];

const shipmentLoadOptions = [
    {
        key: 'Quantity',
        text: 'Quantity'
    },
    {
        key: 'Weight',
        text: 'Weight'
    },
    {
        key: 'Length',
        text: 'Length'
    },
    {
        key: 'Width',
        text: 'Width'
    },
    {
        key: 'Height',
        text: 'Height'
    },
    {
        key: 'Volume',
        text: 'Volume'
    }
];

const shipmentContentsOptions = [
    {
        key: 'IsAlcohol',
        text: 'Contains Alcohol'
    },
    {
        key: 'IsTobacco',
        text: 'Contains Tobacco'
    },
    {
        key: 'IsPrescriptionMedication',
        text: 'Contains Prescription Medication'
    },
    {
        key: 'IsDangerousGoods',
        text: 'Contains Dangerous Goods'
    },
    {
        key: 'IsMeat',
        text: 'Contains Meat'
    },
    {
        key: 'IsPork',
        text: 'Contains Pork'
    },
    {
        key: 'IsBeef',
        text: 'Contains Beef'
    },
    {
        key: 'IsFragile',
        text: 'Is Fragile'
    },
    {
        key: 'IsFlowers',
        text: 'Contains Flowers'
    },
    {
        key: 'IsCake',
        text: 'Contains Cake'
    },
    {
        key: 'IsFood',
        text: 'Contains Food'
    },
    {
        key: 'IsLiquid',
        text: 'Contains Liquid'
    },
    {
        key: 'IsNotRotatable',
        text: 'Is Not Rotatable'
    },
    {
        key: 'IsGlass',
        text: 'Contains Glass'
    },
    {
        key: 'IsBaked',
        text: 'Is Baked'
    },
    {
        key: 'MustBeUpright',
        text: 'Must Be Upright'
    }
];

const stopOptions = [
    {
        key: 'Name',
        text: 'Stop Name',
        dataType: stringDataType
    },
    {
        key: 'AppointmentDate',
        text: 'Appointment Date',
        dataType: dateDataType
    },
    {
        key: 'TimeWindowStart',
        text: 'Time Window Start',
        dataType: dateDataType
    },
    {
        key: 'TimeWindowEnd',
        text: 'Time Window End',
        dataType: dateDataType
    },
    {
        parentObject: 'AddressComponents',
        key: 'City',
        text: 'Address City/Suburb',
        dataType: stringDataType
    },
    {
        parentObject: 'AddressComponents',
        key: 'Province',
        text: 'Address Province/State',
        dataType: stringDataType
    },
    {
        parentObject: 'AddressComponents',
        key: 'ProvinceCode',
        text: 'Address Province/State Code',
        dataType: stringDataType
    },
    {
        parentObject: 'AddressComponents',
        key: 'Postcode',
        text: 'Address Post Code',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Name',
        text: 'Contact Name',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Phone',
        text: 'Contact Phone',
        dataType: stringDataType
    },
    {
        parentObject: 'Contact',
        key: 'Email',
        text: 'Contact Email',
        dataType: stringDataType
    },
    {
        parentObject: 'Customer',
        key: 'Name',
        text: 'Customer Name',
        dataType: stringDataType
    }
];

export {
    mainOperatorOptions,
    booleanOperatorOptions,
    shipmentOptions,
    shipmentLoadOptions,
    shipmentContentsOptions,
    stopOptions
};
